import React from "react"

import Layout from "../components/_Layout"
import Head from "../components/_Head"
import Container from "../components/Container"

const NotFoundPage = () => (
  <Layout>
    <Head title="404: Not found" />
    <Container>
      <h1 style={{ marginTop: 120 }}>Sorry Cowboy</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
    </Container>
  </Layout>
)

export default NotFoundPage
